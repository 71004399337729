<template>
  <div class="home_top">
    <div class="left_item">
      <h3> <br> Nuestras <br>soluciones</h3>
      <p>Se ofrecen soluciones de pago, en cuanto a métodos de pago</p>
      <div>
        <div class="downloannow" @click="goDownloan()">Empezar ahora</div>
      </div>
    </div>
    <div class="right_item">
      <img src="@/assets/images/img_mobile_computer.png" alt="">
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    goDownloan() {
      // window.location.href = 'https://aws-logs-780801304422-ap-south-1.s3.ap-south-1.amazonaws.com/RapidEfectivo1.0.1.apk'
    }
  }
}
</script>

<style lang="scss" scoped>
.home_top {
  width: 100%;
  min-width: 1400px;
  height: 739px;
  background: #fff;
  position: relative;
  padding: 134px 180px 0;
  .left_item {
    float: left;
    padding-top: 20px;
    h3 {
      font-size: 68px;
      color: #724CC6;
      font-weight: 700;
      margin-bottom: 45px;
      line-height: 75px;
      width: 400px;
    }
    p {
      font-size: 20px;
      color: #724CC6;
      margin-bottom: 100px;
      line-height: 30px;
      width: 440px
    }
  }
  .right_item {
    float: right;
    margin-top: 60px;
    img {
      width: 600px;
    }
  }
  .downloannow {
    width: 220px;
    height: 60px;
    background: #060300;
    border-radius: 5px;
    line-height: 60px;
    text-align: center;
    font-weight: 700;
    font-size: 20px;
    color: white;
  }
}
</style>
