<template>
  <div class="hotel-home">
    <div class="con_nav">
      <nav-tab :nav-data="navData" />
    </div>
    <div class="con_center">
      <SolutionTop />
      <SolutionBottom />
    </div>
    <div class="con_bottom">
      <layout-bottom />
    </div>
  </div>
</template>

<script>
import NavTab from '@/components/NavTab'
import LayoutBottom from '@/components/LayoutBottom'
import SolutionTop from '@/components/solution/solutionTop'
import SolutionBottom from '@/components/solution/solutionBottom'
export default {
  components: {
    NavTab,
    SolutionTop,
    SolutionBottom,
    LayoutBottom
  },
  data() {
    return {
      navData: {
        index: 2,
        list: [
          {
            name: 'Productos y servicios',
            link: '/'
          },
          {
            name: 'Sobre Nosotros',
            link: '/about_us'
          },
          {
            name: 'Soluciones',
            link: '/solution'
          },
          {
            name: 'Mercados',
            link: '/market'
          }]
      }
    }
  }
}
</script>
<style lang="scss" scoped>
// .con_center{
//     background: url("~@/assets/images/loanImg/bn_ee.png") no-repeat top left;

// }
</style>
