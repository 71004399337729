<template>
  <div class="home_bottom">
    <div class="bg">
      <div class="div_payout">
        <div class="payout_desc">
          <p class="title">Payout de FACILPAY</p>
          <p class="text2">Brindamos una experiencia impecable, en la que las empresas pueden operar fácilmente el desafío de pagar a los contratistas en diferentes países y monedas con seguridad y rapidez. En nuestro panel de control, tiene una visibilidad clara sobre el saldo y la actividad transaccional, y también puede realizar varios pagos al mismo tiempo con la función por lotes</p>
        </div>
        <img class="right_img" src="@/assets/images/img_payout.png" alt="">
      </div>
      <div class="payout_step">
        <div class="title">La solución FACILPAY Payout se adapta a cualquier tipo de negocio o modelo operativo</div>
        <div class="div_step">
          <div>
            <div class="text2">
              <img class="img_icon" src="@/assets/images/icon_dollar.png" alt="">
              <br>Agregar saldo mediante transferencia bancaria
            </div>
            <div class="text2 text_margin_top">
              <img class="img_icon" src="@/assets/images/icon_simple_payment.png" alt="">
              <br>Cobrar pagos procesados por Facilpay
            </div>
          </div>
          <img class="img_next" src="@/assets/images/img_two_arrow.png" alt="">
          <div class="text2 text_height">
            <img class="img_step" src="@/assets/images/img_three_step1.png" alt="">
            <br>Su saldo se pagos
          </div>
          <img class="img_arrow" src="@/assets/images/img_three_step_line.png" alt="">
          <div class="text2 text_height">
            <img class="img_step" src="@/assets/images/img_three_step2.png" alt="">
            <br>Solicite el pago con los documentos del beneficiario
          </div>
          <img class="img_arrow" src="@/assets/images/img_three_step_line.png" alt="">
          <div class="text2 text_height">
            <img class="img_step" src="@/assets/images/img_three_step3.png" alt="">
            <br>Pago completado en hasta 3 días hábiles
          </div>
        </div>
      </div>
      <div class="div_wallet">
        <img class="left_img" src="@/assets/images/img_wallet.png" alt="">
        <div class="wallet_desc">
          <p class="title">Servicio al consumidor</p>
          <p class="text2">Se ofrece atención al cliente por medio de chat por Whatsapp, correo electrónico o teléfono celular, personal idóneo capacitado en todos los idiomas, para prestar el mejor servicio al consumidor </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.home_bottom {
  min-width: 1400px;
  width: 100%;
  .bg {
    background: #FFFFFF;
  }
  .div_payout {
    min-width: 1400px;
    background: #3D2158;
    align-items: center;
    text-align: center;
    color: white;
    padding: 36px 180px;
    display: flex;
    justify-content: space-between;
    .payout_desc {
      padding: 0 86px 0 0;
      .title {
        text-align: left;
        font-size: 34px;
        font-weight: 700;
      }
      .text2 {
        text-align: left;
        margin-top: 50px;
        font-size: 18px;
        line-height: 25px;
      }
    }
    .right_img {
      width: 462px;
    }
  }
  .div_wallet {
    min-width: 1400px;
    background: white;
    align-items: center;
    text-align: center;
    color: #724CC6;
    padding: 18px 180px;
    display: flex;
    justify-content: space-between;
    .wallet_desc {
      padding: 0 0 0 53px;
      .title {
        text-align: right;
        font-size: 34px;
        font-weight: 700;
      }
      .text2 {
        max-width: 500px;
        text-align: right;
        margin-top: 50px;
        font-size: 18px;
        line-height: 25px;
      }
    }
    .left_img {
      width: 450px;
    }
  }
  .payout_step {
    background: #F5F5F5;
    padding: 110px 180px 91px;
    text-align: center;
    color: #724CC6;
    .title {
      flex: 1;
      text-align: center;
      font-size: 34px;
      font-weight: 700;
    }
    .div_step {
      padding: 70px 0 0 0;
      align-items: center;
      display: flex;
      justify-content: space-between;
      .img_icon {
        width: 66px;
        height: 66px;
        margin-bottom: 27px;
      }
      .img_next {
        width: 112px;
      }
      .img_step {
        margin-top: 30px;
        width: 46px;
        margin-bottom: 27px;
      }
      .img_arrow {
        width: 78.5px;
      }
      .text2 {
        width: 200px;
        text-align: center;
        font-size: 18px;
        line-height: 25px;
      }
      .text_height {
        height: 100px;
      }
      .text_margin_top {
        margin-top: 100px;
      }
    }
  }
}
</style>
